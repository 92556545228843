import { useState } from "react";
import "./App.css";
import Header from "./components/Header";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
// import Portfolio from "./components/Portfolio";
import Skills from "./components/Skills";
import Services from "./components/Services";
// import Home from "./components/Home";
import About from "./components/About";
import Footer from "./components/Footer";
import ContactMe from "./components/ContactMe";
import ProjectInMind from "./components/ProjectInMind";
import Hero from "./components/Hero";
import StarsCanvas from "./components/canvas/Stars";
import { Toaster } from "react-hot-toast";
import Experience from "./components/Experience";

function App() {
  const [count, setCount] = useState(0);

  return (
    <>
      <div className="App">
        <Header />
        <main className="main">
          <Hero />
          <StarsCanvas />
          {/* <Home /> */}
          <About />
          <Experience />
          <Skills />
          <Services />
          {/* <Portfolio /> */}
          <ProjectInMind />
          <ContactMe />
          <Footer />
          <Toaster />
        </main>
      </div>
    </>
  );
}

export default App;
