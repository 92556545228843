import { motion } from "framer-motion";
import { styles } from "../styles";
import { EarthCanvas } from "./canvas";
import { slideIn } from "../utils/motion";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { useEffect, useState } from "react";

const Hero = () => {
  const [showTyping, setShowTyping] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowTyping(true);
    }, 500); // Start typing "Shouting Medic" after 2 seconds

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 868);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call the function initially to set the state

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [typewriterText] = useTypewriter({
    words: [
      "There",
      "Welcome!",
      "I'm a Tech Enthusiast",
      "gimme your problem",
      "and be sure to be solved",
      "I'm Arfaz",
    ],
    loop: 1,
    typeSpeed: 70,
    deleteSpeed: 40,
    delaySpeed: 1000,
  });

  return (
    <section
      className={`relative w-full mx-auto flex flex-col ${
        isMobile ? "justify-start h-[768px]" : " h-screen justify-center"
      } items-center`}
      id="home"
    >
      <div className="xl:mt-12 flex flex-col xl:flex-row items-center justify-center w-full px-4 sm:px-6 lg:px-8">
        {/* Order this in flex to come at last when isMobile, also make it horizontal */}
        <div
          className={`w-10 flex ${
            isMobile ? "absolute bottom-[240px] mr-10" : "flex-col"
          } gap-10 align-start`}
        >
          <a
            href="https://linkedin.com/in/sheikharfazahamed"
            target="_blank"
            rel="noopener noreferrer"
            className="home__social-icon"
          >
            <i className="uil uil-linkedin-alt"></i>
          </a>
          <a
            href="https://github.com/sheikharfaz"
            target="_blank"
            rel="noopener noreferrer"
            className="home__social-icon"
          >
            <i className="uil uil-github-alt"></i>
          </a>
        </div>
        <div
          className={`w-full mx-auto ${styles.paddingX} flex-1 flex flex-col xl:flex-row items-center justify-between h-full`}
        >
          {/* make the size of of the mobile */}
          {isMobile && (
            <div className="w-full h-64 sm:h-96 md:h-full mt-10 xl:mt-0">
              <EarthCanvas />
            </div>
          )}
          <div className="flex flex-col justify-center items-center xl:items-start text-center xl:text-left">
            <h1 className={`${styles.heroHeadText} home__title`}>
              <span className="opacity-[0.8]"> Hi, </span>
              <span className="text-[#915EFF]">
                {showTyping && (
                  <>
                    {typewriterText}
                    <Cursor />
                  </>
                )}
              </span>
            </h1>
            <h2 className="text-white-100 font-bold opacity-[0.5]">
              Full Stack Developer
            </h2>
            <p
              className={`${styles.heroSubText} mt-6 text-white-60 ${
                isMobile ? "w-60" : ""
              }`}
            >
              Turning code into World reality,{" "}
              <br className="sm:block hidden" />
              Producing quality work.
            </p>
          </div>
          {!isMobile && (
            <motion.div
              variants={slideIn("right", "tween", 0.2, 1)}
              className="w-full xl:w-1/2 h-64 sm:h-96 md:h-full mt-10 xl:mt-0"
            >
              <EarthCanvas />
            </motion.div>
          )}
        </div>
      </div>
      <div
        className={`${
          isMobile
            ? "absolute bottom-[120px] mr-5"
            : "xs:bottom-10 bottom-20 w-full flex justify-center items-end"
        }`}
      >
        <a href="#about" className="home__scroll-button button--flex">
          <i className="uil uil-mouse-alt home__scroll-mouse"></i>
          <span className="home__scroll-name">Scroll Down</span>
          <i className="uil uil-arrow-down home__scroll-arrow"></i>
        </a>
      </div>
    </section>
  );
};

export default Hero;
