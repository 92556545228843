import React, { useState } from "react";

const Skills = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSkills = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="skills section" id="skills">
      <h2 className="section__title">Skills</h2>
      <span className="section__subtitle">My technical level</span>

      <div className="skills__container container">
        <div className="skills__content-wrapper">
          <div
            className={`skills__content ${
              activeIndex === 0 ? "skills__open" : "skills__close"
            }`}
          >
            <div className="skills__header" onClick={() => toggleSkills(0)}>
              <i className="uil uil-brackets-curly skills__icon"></i>
              <div>
                <h1 className="skills__title">Frontend Developer</h1>
                <span className="skills__subtitle">More than 4 years</span>
              </div>
              <i className="uil uil-angle-down skills__arrow"></i>
            </div>
            {activeIndex === 0 && (
              <div className="skills__list grid">
                {[
                  { name: "HTML", percentage: 90 },
                  { name: "CSS", percentage: 85 },
                  { name: "JavaScript", percentage: 87 },
                  { name: "Wordpress", percentage: 87 },
                  { name: "TypeScript", percentage: 82 },
                ].map((skill, index) => (
                  <div key={index} className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">{skill.name}</h3>
                      <span className="skills__number">
                        {skill.percentage}%
                      </span>
                    </div>
                    <div className="skills__bar">
                      <span
                        className="skills__percentage"
                        style={{ width: `${skill.percentage}%` }}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={`skills__content ${
              activeIndex === 1 ? "skills__open" : "skills__close"
            }`}
          >
            <div className="skills__header" onClick={() => toggleSkills(1)}>
              <i className="uil uil-server-network skills__icon"></i>
              <div>
                <h1 className="skills__title">Backend Developer</h1>
                <span className="skills__subtitle">More than 6 years</span>
              </div>
              <i className="uil uil-angle-down skills__arrow"></i>
            </div>
            {activeIndex === 1 && (
              <div className="skills__list grid">
                {[
                  { name: "Node JS", percentage: 50 },
                  { name: ".Net Core", percentage: 86 },
                  { name: "Python", percentage: 40 },
                  { name: "SQL Server", percentage: 90 },
                  { name: "Postgre", percentage: 70 },
                  { name: "EF Core", percentage: 84 },
                  { name: "Dapper", percentage: 84 },
                  { name: "Mongoose", percentage: 40 },
                ].map((skill, index) => (
                  <div key={index} className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">{skill.name}</h3>
                      <span className="skills__number">
                        {skill.percentage}%
                      </span>
                    </div>
                    <div className="skills__bar">
                      <span
                        className="skills__percentage"
                        style={{ width: `${skill.percentage}%` }}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={`skills__content ${
              activeIndex === 2 ? "skills__open" : "skills__close"
            }`}
          >
            <div className="skills__header" onClick={() => toggleSkills(2)}>
              <i className="uil uil-lock-access skills__icon"></i>
              <div>
                <h1 className="skills__title">DevSecOps</h1>
                <span className="skills__subtitle">More than 3 years</span>
              </div>
              <i className="uil uil-angle-down skills__arrow"></i>
            </div>
            {activeIndex === 2 && (
              <div className="skills__list grid">
                {[
                  { name: "Azure DevOps", percentage: 70 },
                  { name: "CI/CD", percentage: 70 },
                  { name: "Terraform", percentage: 75 },
                  { name: "Octopus", percentage: 70 },
                  { name: "Splunk Logging", percentage: 80 },
                  { name: "Redis Cache", percentage: 85 },
                ].map((skill, index) => (
                  <div key={index} className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">{skill.name}</h3>
                      <span className="skills__number">
                        {skill.percentage}%
                      </span>
                    </div>
                    <div className="skills__bar">
                      <span
                        className="skills__percentage"
                        style={{ width: `${skill.percentage}%` }}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div
            className={`skills__content ${
              activeIndex === 3 ? "skills__open" : "skills__close"
            }`}
          >
            <div className="skills__header" onClick={() => toggleSkills(3)}>
              <i className="uil uil-cloud skills__icon"></i>
              <div>
                <h1 className="skills__title">Cloud</h1>
                <span className="skills__subtitle">More than 3 years</span>
              </div>
              <i className="uil uil-angle-down skills__arrow"></i>
            </div>
            {activeIndex === 3 && (
              <div className="skills__list grid">
                {[
                  { name: "AWS", percentage: 88 },
                  { name: "Akeyless", percentage: 77 },
                  { name: "Architecture Design", percentage: 65 },
                  { name: "Azure", percentage: 87 },
                ].map((skill, index) => (
                  <div key={index} className="skills__data">
                    <div className="skills__titles">
                      <h3 className="skills__name">{skill.name}</h3>
                      <span className="skills__number">
                        {skill.percentage}%
                      </span>
                    </div>
                    <div className="skills__bar">
                      <span
                        className="skills__percentage"
                        style={{ width: `${skill.percentage}%` }}
                      ></span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Skills;
