import React, { useState } from "react";
import { motion } from "framer-motion";
import emailjs from "emailjs-com";

const CVModal = ({ isOpen, onClose, onDownload }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleDownload = () => {
    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    setIsSubmitting(true);
    const templateParams = {
      user_email: email,
    };

    emailjs
      .send(
        "your_service_id", // Replace with your EmailJS service ID
        "your_template_id", // Replace with your EmailJS template ID
        templateParams,
        "your_user_id" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setIsSubmitting(false);
          setError("");
          setEmail("");

          // Call the onDownload function to trigger the actual CV download
          onDownload();

          onClose(); // Close the modal
        },
        (err) => {
          console.log("FAILED...", err);
          setIsSubmitting(false);
          setError("Failed to send email. Please try again later.");
        }
      );
  };

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
      onClick={onClose}
    >
      <motion.div
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: "0", opacity: 1 }}
        exit={{ y: "100vh", opacity: 0 }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
        style={{
          background: "linear-gradient(135deg, #150c41, #6e57e0)",
          padding: "2rem",
          borderRadius: "20px",
          boxShadow: "0 15px 40px rgba(0, 0, 0, 0.3)",
          width: "90%",
          maxWidth: "500px",
          textAlign: "center",
          color: "#fff",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <motion.h2 initial={{ opacity: 0, x: -50 }} animate={{ opacity: 1, x: 0 }} transition={{ delay: 0.4 }} style={{ marginBottom: "1rem" }}>
          Enter Your Email to Download My CV
        </motion.h2>

        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your email"
          style={{
            padding: "0.75rem",
            width: "100%",
            maxWidth: "300px",
            marginBottom: "1rem",
            color: "grey",
            borderRadius: "8px",
            border: "none",
            fontSize: "1rem",
          }}
        />

        {error && <p style={{ color: "red", marginBottom: "1rem" }}>{error}</p>}

        <motion.button
          whileHover={{
            scale: 1.1,
            boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#ff7e5f",
            background: "linear-gradient(135deg, #ff7e5f, #feb47b)",
          }}
          whileTap={{ scale: 0.95 }}
          onClick={handleDownload}
          disabled={isSubmitting}
          style={{
            padding: "0.75rem 1.5rem",
            backgroundColor: "#00C9FF",
            background: "linear-gradient(135deg, #00C9FF, #92FE9D)",
            color: "#fff",
            border: "none",
            borderRadius: "50px",
            cursor: "pointer",
            fontSize: "1.2rem",
            transition: "all 0.2s ease-in-out",
          }}
        >
          {isSubmitting ? "Downloading..." : "Download"}
        </motion.button>
      </motion.div>
    </div>
  );
};

export default CVModal;
