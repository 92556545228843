import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__bg">
        <div className="footer__container container grid">
          <div>
            <h1 className="footer__title">Arfaz Portfolio</h1>
            <span className="footer__subtitle">Full stack developer</span>
          </div>
          <ul className="footer__links">
            <li>
              <a href="#services" className="footer__link">
                Services
              </a>
            </li>

            <li>
              <a href="#skills" className="footer__link">
                Skills
              </a>
            </li>

            <li>
              <a href="#contact" className="footer__link">
                Contactme
              </a>
            </li>
          </ul>

          <div className="footer__socials">
            <a
              href="https://facebook.com"
              target="_blank"
              className="footer__social"
            >
              <i className="uil uil-facebook-f"></i>
            </a>

            <a
              href="https://instagram.com"
              target="_blank"
              className="footer__social"
            >
              <i className="uil uil-instagram"></i>
            </a>

            <a
              href="https://twitter.com"
              target="_blank"
              className="footer__social"
            >
              <i className="uil uil-twitter-alt"></i>
            </a>
          </div>
        </div>

        <p className="footer__copy">&#169; Arfaz. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
