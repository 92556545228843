import React from "react";
import CVModal from "./ui/CVModal";
import { useState } from "react";

const About = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleDownload = () => {
    // Logic to download your CV
    const link = document.createElement("a");
    link.href = "assets/pdf/resume.pdf"; // Replace with the actual path to your CV
    link.download = "Arfaz_CV.pdf";
    link.click();

    setModalOpen(false); // Close the modal after downloading
  };

  return (
    <section className="about section" id="about">
      <h2 className="section__title ">About Me</h2>
      <span className="section__subtitle">My introduction</span>

      <div className="about__container container grid">
        {/* <img src="public/img/about.png" alt="" className="about__img" /> */}
        <div
          className={`home__img flex ${
            isMobile ? "ml-10" : ""
          } justify-center items-center`}
        >
          <svg
            className="home__blob"
            viewBox="0 0 250 187"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <mask id="mask0" mask-type="alpha">
              <path
                d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 165.547 
                                    130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 129.362C2.45775 
                                    97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 -0.149132 97.9666 
                                    0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"
              />
            </mask>
            <g mask="url(#mask0)">
              <path
                d="M190.312 36.4879C206.582 62.1187 201.309 102.826 182.328 134.186C163.346 
                                    165.547 130.807 187.559 100.226 186.353C69.6454 185.297 41.0228 161.023 21.7403 
                                    129.362C2.45775 97.8511 -7.48481 59.1033 6.67581 34.5279C20.9871 10.1032 59.7028 
                                    -0.149132 97.9666 0.00163737C136.23 0.303176 174.193 10.857 190.312 36.4879Z"
              />
              <image
                className="home__blob-img"
                x="17"
                y="38"
                xlinkHref="/img/photo.png"
              />
            </g>
          </svg>
        </div>
        <div className="about__data">
          <p className="about__description">
            Web developer, with extensive knowledge and years of experience,
            working in web technologies and UI/UX design, delivering quality
            work.
          </p>
          <div className="about__info">
            <div>
              <span className="about__info-title">06+</span>
              <span className="about__info-name">
                Years <br /> experience
              </span>
            </div>

            <div>
              <span className="about__info-title">20+</span>
              <span className="about__info-name">
                Completed <br /> projects
              </span>
            </div>

            <div>
              <span className="about__info-title">05+</span>
              <span className="about__info-name">
                Companies <br /> worked
              </span>
            </div>
          </div>

          {/* <a href="resume.pdf" className="button button--flex"> */}
          <button
            className={`button button--flex ${isMobile ? "ml-20" : ""}`}
            onClick={() => setModalOpen(true)}
          >
            Download CV <i className="uil uil-download-alt button__icon"></i>
            {/* </a> */}
          </button>
        </div>
      </div>

      <CVModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onDownload={handleDownload}
      />
    </section>
  );
};

export default About;
