import React, { useState, useEffect } from "react";

const Header = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    // Set initial theme
    const savedTheme = localStorage.getItem("selected-theme") || "light";
    setTheme(savedTheme);
    document.body.classList.toggle("dark-theme", savedTheme === "dark");

    // Handle scroll event for header background change
    const handleScroll = () => {
      setIsScrolled(window.scrollY >= 80);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    document.body.classList.toggle("dark-theme", newTheme === "dark");
    localStorage.setItem("selected-theme", newTheme);
  };

  return (
    <header className={`header ${isScrolled ? "scroll-header" : ""}`}>
      <nav className="nav container">
        {/* take a logo from public folder logo.png with logo width and height */}
        <img
          src="/img/logo.png"
          alt="logo"
          className="nav__logo"
          width={50}
          height={50}
        />

        {/* <a href="#home" className="nav__logo">
          Shouting Medic
        </a> */}

        <div className={`nav__menu ${menuVisible ? "show-menu" : ""}`}>
          <ul className={`nav__list ${menuVisible ? "grid" : ""}`}>
            <li className="nav__item">
              <a href="#home" className="nav__link" onClick={closeMenu}>
                <i className="uil uil-estate nav__icon"></i> Home
              </a>
            </li>
            <li className="nav__item">
              <a href="#about" className="nav__link" onClick={closeMenu}>
                <i className="uil uil-user nav__icon"></i> About
              </a>
            </li>
            <li className="nav__item">
              <a href="#skills" className="nav__link" onClick={closeMenu}>
                <i className="uil uil-file-alt nav__icon"></i> Skills
              </a>
            </li>
            <li className="nav__item">
              <a href="#services" className="nav__link" onClick={closeMenu}>
                <i className="uil uil-suitcase-alt nav__icon"></i> Services
              </a>
            </li>
            {/* <li className="nav__item">
              <a href="#portfolio" className="nav__link" onClick={closeMenu}>
                <i className="uil uil-scenery nav__icon"></i> Portfolio
              </a>
            </li> */}
            <li className="nav__item">
              <a href="#contact" className="nav__link" onClick={closeMenu}>
                <i className="uil uil-message nav__icon"></i> Contactme
              </a>
            </li>
          </ul>
          <i className="uil uil-times nav__close" onClick={closeMenu}></i>
        </div>

        <div className="nav__btns">
          <i
            className={`uil ${
              theme === "light" ? "uil-moon" : "uil-sun"
            } change-theme`}
            onClick={toggleTheme}
          ></i>
          <div className="nav__toggle" onClick={toggleMenu}>
            <i className="uil uil-apps"></i>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
