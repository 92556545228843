import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const modalVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const backdropVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
};

const ServiceItem = ({ title, icon, services }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="services__content">
      <div>
        <i className={`uil ${icon} services__icon`}></i>
        <h3 className="services__title">{title}</h3>
      </div>
      <span className="button button--flex button--small button--link services__button" onClick={() => setIsOpen(true)}>
        View More
        <i className="uil uil-arrow-right button__icon"></i>
      </span>

      <AnimatePresence>
        {isOpen && (
          <motion.div className="services__modal" variants={backdropVariants} initial="hidden" animate="visible" exit="hidden">
            <motion.div className="services__modal-content" variants={modalVariants} initial="hidden" animate="visible" exit="hidden">
              <h4 className="services__modal-title">{title}</h4>
              <i className="uil uil-times services__modal-close" onClick={() => setIsOpen(false)}></i>
              <ul className="services__modal-services">
                {services.map((service, index) => (
                  <li key={index} className="services__modal-service">
                    <i className="uil uil-check-circle services__modal-icon"></i>
                    <p>{service}</p>
                  </li>
                ))}
              </ul>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const Services = () => {
  const services = [
    {
      title: "Static Sites",
      icon: "uil-file",
      services: ["Simple static websites with fast loading times.", "Optimized for SEO and mobile devices.", "No server-side processing required."],
    },
    {
      title: "Dynamic Sites",
      icon: "uil-sync",
      services: ["Dynamic content generation based on user interactions.", "Integration with databases for real-time updates.", "Custom functionalities tailored to your needs."],
    },
    {
      title: "CRM",
      icon: "uil-database",
      services: ["Customer relationship management solutions.", "Custom dashboards and reporting tools.", "Integration with existing systems and tools."],
    },
    {
      title: "CMS",
      icon: "uil-file-alt",
      services: ["Content management systems for easy updates.", "Customizable templates and themes.", "User-friendly interfaces for non-technical users."],
    },
    {
      title: "Custom Websites",
      icon: "uil-edit",
      services: ["Tailored web solutions to meet specific needs.", "Unique designs and user experiences.", "Scalable and maintainable architecture."],
    },
    {
      title: "Animated Websites",
      icon: "uil-scenery",
      services: ["Interactive animations and transitions.", "Engaging visual effects to enhance user experience.", "Optimized for performance across devices."],
    },
  ];

  return (
    <section className="services section" id="services">
      <h2 className="section__title">Services</h2>
      <span className="section__subtitle">What I offer</span>

      <div className="services__container container grid">
        {services.map((service, index) => (
          <ServiceItem key={index} title={service.title} icon={service.icon} services={service.services} />
        ))}
      </div>
    </section>
  );
};

export default Services;
